import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { ApiPaginatedResponse, ApiResponse, User, UserFleetsRequest, UserRolesRequest } from '@/api/types'
import type { FetchOptions } from 'ofetch'

export default class UserService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<User[]>> {
    return await this.call('/v1/users', options)
  }

  async retrieve(id: number | string, options?: FetchOptions<'json'>): Promise<ApiResponse<User>> {
    return await this.call(`/v1/users/${id}`, options)
  }

  async syncFleets(userId: number | string, data: UserFleetsRequest): Promise<any> {
    return await this.call(`/v1/users/${userId}/fleets/sync`, {
      method: 'post',
      body: data,
    })
  }

  async syncRoles(userId: number | string, data: UserRolesRequest): Promise<any> {
    return await this.call(`/v1/users/${userId}/roles/sync`, {
      method: 'post',
      body: data,
    })
  }
}
